// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.admin-textarea textarea {\n  padding-right: 40px;\n  resize: none;\n  border: none;\n  border-radius: 0px;\n  border-bottom: 1px solid #bfcbd9;\n}\n.createPost-container {\n  position: relative;\n}\n.createPost-container .createPost-main-container {\n  padding: 40px 45px 20px 50px;\n}\n.createPost-container .createPost-main-container .postInfo-container {\n  position: relative;\n  margin-bottom: 10px;\n}\n.createPost-container .createPost-main-container .postInfo-container:after {\n  content: \"\";\n  display: table;\n  clear: both;\n}\n.createPost-container .createPost-main-container .postInfo-container .postInfo-container-item {\n  float: left;\n}\n.createPost-container .word-counter {\n  width: 40px;\n  position: absolute;\n  right: 10px;\n  top: 0px;\n}\nh4,\np {\n  margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#034073",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409eff"
};
module.exports = exports;
